import { Container, Title, Text, Button, Stack, Group, rem, Menu } from '@mantine/core';
import { Link } from '@remix-run/react';

interface ErrorBoundaryProps {
  error?: Error;
  heading?: string;
  message?: string;
  actionText?: string;
  actionLink?: string;
  showHome?: boolean;
  showRefresh?: boolean;
  showContact?: boolean;
}

export default function ErrorBoundaryComponent({
  error,
  heading = "Something went wrong",
  message = "We're sorry, but we encountered an unexpected error. Please try again later.",
  actionText,
  actionLink,
  showHome = true,
  showRefresh = false,
  showContact = true,
}: ErrorBoundaryProps) {
  // Check if this is a 404 error
  const is404 = error?.message?.includes('404') || error?.stack?.includes('404');
  // Network error detection
  const isNetworkError = error?.message?.includes('socket hang up') ||
    error?.message?.includes('Failed to fetch') ||
    error?.message?.includes('Network Error');

  // Payment specific error detection
  const isPaymentError = error?.message?.includes('payment') ||
    error?.message?.includes('card') ||
    error?.message?.includes('transaction');

  // Not found error detection
  const isNotFound = error?.message?.includes('404') ||
    error?.message?.includes('not found');

  // Authorization error detection
  const isAuthError = error?.message?.includes('401') ||
    error?.message?.includes('403') ||
    error?.message?.includes('unauthorized');

  // Customize message based on error type
  const errorHeading = isNetworkError ? "Network Error" :
    isPaymentError ? "Payment Error" :
      isNotFound ? "Page Not Found" :
        isAuthError ? "Access Denied" :
          heading;

  const errorMessage = isNetworkError ? "Please check your internet connection and try again." :
    isPaymentError ? "There was a problem processing your payment. Your card has not been charged." :
      isNotFound ? "The page you're looking for doesn't exist." :
        isAuthError ? "You don't have permission to access this page." :
          message;

  return (
    <Container size="md" py="xl">
      <Stack gap="lg" align="center" justify="center" style={{ minHeight: rem(400) }}>
        {/* Error Icon */}
        <div style={{ fontSize: rem(64), color: 'var(--mantine-color-red-6)' }}>
          {isNetworkError && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <line x1="1" y1="1" x2="23" y2="23"></line>
              <path d="M16.72 11.06A10.94 10.94 0 0 1 19 12.55"></path>
              <path d="M5 12.55a10.94 10.94 0 0 1 5.17-2.39"></path>
              <path d="M10.71 5.05A16 16 0 0 1 22.58 9"></path>
              <path d="M1.42 9a15.91 15.91 0 0 1 4.7-2.88"></path>
              <path d="M8.53 16.11a6 6 0 0 1 6.95 0"></path>
              <line x1="12" y1="20" x2="12.01" y2="20"></line>
            </svg>
          )}
          {isPaymentError && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <rect x="2" y="4" width="20" height="16" rx="2"></rect>
              <path d="M7 15h0M2 9.5h20"></path>
            </svg>
          )}
          {isNotFound && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <circle cx="12" cy="12" r="10"></circle>
              <path d="M16 16s-1.5-2-4-2-4 2-4 2"></path>
              <line x1="9" y1="9" x2="9.01" y2="9"></line>
              <line x1="15" y1="9" x2="15.01" y2="9"></line>
            </svg>
          )}
          {isAuthError && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
              <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
            </svg>
          )}
          {!isNetworkError && !isPaymentError && !isNotFound && !isAuthError && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <circle cx="12" cy="12" r="10"></circle>
              <line x1="12" y1="8" x2="12" y2="12"></line>
              <line x1="12" y1="16" x2="12.01" y2="16"></line>
            </svg>
          )}
        </div>

        {/* Error Message */}
        <Stack gap="xs" align="center" style={{ textAlign: 'center' }}>
          <Title order={1} size="h2">{errorHeading}</Title>
          <Text size="lg" c="dimmed" maw={500}>{errorMessage}</Text>

          {/* Show technical error in development */}
          {process.env.NODE_ENV === 'development' && error && (
            <Text size="sm" style={{
              fontFamily: 'monospace',
              padding: '1rem',
              background: 'var(--mantine-color-gray-0)',
              borderRadius: 'var(--mantine-radius-md)',
              maxWidth: '100%',
              overflow: 'auto'
            }}>
              {error.message}
            </Text>
          )}
        </Stack>

        {/* Action Buttons */}
        <Group gap="sm">
          {showRefresh && (
            <Button
              variant="light"
              onClick={() => window.location.reload()}
              leftSection={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M21.5 2v6h-6M21.34 15.57a10 10 0 1 1-.57-8.38" />
                </svg>
              }
            >
              Try Again
            </Button>
          )}

          {showHome && (
            <Button
              component={Link}
              to="/"
              variant="filled"
              c="white"
              td="none"
              leftSection={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="m3 9 9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
                  <polyline points="9 22 9 12 15 12 15 22" />
                </svg>
              }
            >
              Back to Home
            </Button>
          )}

          {showContact && (
            <Menu shadow="md" width={200}>
              <Menu.Target>
                <Button
                  variant="light"
                  leftSection={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z" />
                    </svg>
                  }
                >
                  Contact Us
                </Button>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Item
                  component="a"
                  href="sms:+14434322022"
                  leftSection={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z" />
                    </svg>
                  }
                >
                  Text Us
                </Menu.Item>
                <Menu.Item
                  component="a"
                  href="mailto:support@baltimorehomecleaning.com"
                  leftSection={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <rect width="20" height="16" x="2" y="4" rx="2" />
                      <path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7" />
                    </svg>
                  }
                >
                  Email Us
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          )}

          {actionText && actionLink && (
            <Button
              component={Link}
              to={actionLink}
              variant="light"
            >
              {actionText}
            </Button>
          )}
        </Group>
      </Stack>
    </Container>
  );
}